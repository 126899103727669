import React from 'react';
import { Grid, Box, Typography, Button } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const GallerySection = () => {


    return (
        <Box maxWidth="lg" sx={{
            textAlign: 'center', padding: { xs: 2, md: 4 }, backgroundColor: "#f9ffe9", borderRadius: 10
        }}>
            <Box sx={{ textAlign: "center", marginBottom: "32px" }}>
                <Typography variant="h4">
                    <span style={{ fontWeight: "bold", color: "#ffbf00" }}>Gallery</span>
                </Typography>
                <Typography variant="body1"
                    align="center"
                    sx={{
                        color: '#7f8c8d',
                        mb: 4,
                        fontStyle: 'italic',
                    }}>
                    A collection of captivating images from various activities of Shubadinam Heritage Services.
                </Typography>
            </Box>


            <div className="gallery-images" onContextMenu={(e) => e.preventDefault()}>
                <a href="/gallery" style={{ textDecoration: "none" }}>

                    <img
                        loading='lazy'
                        data-aos="zoom-in"
                        src={require("../../images/gallery/Ganapathy_Homam/20240724_091331.webp")}
                        alt="A jar of red smoothie with lemon slices"
                        className="gallery-images"
                        style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                    />
                    <img data-aos="zoom-in"
                        loading='lazy'
                        src={require("../../images/gallery/Ganapathy_Homam/20240724_081654.webp")}
                        alt="A variety of healthy dishes on a table"
                        className="gallery-images"
                        style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                    />
                    <img data-aos="zoom-in" loading='lazy'
                        src={require("../../images/gallery/Avahanti_Homam/20240809_085534.webp")}
                        alt="Glasses of orange drink with strawberries and lime"
                        className="gallery-images"
                        style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                    />
                    <img data-aos="zoom-in" loading='lazy'
                        src={require("../../images/gallery/Ganapathy_Homam/20240724_072152.webp")}
                        alt="A bowl of salad with a fried egg on top"
                        className="gallery-images"
                        style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                    />
                    <img
                        data-aos="zoom-in" loading='lazy'
                        src={require("../../images/gallery/Surya_Namaskar/20240714_083117.webp")}
                        alt="A jar of red smoothie with lemon slices"
                        className="gallery-images"
                        style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                    />
                    <img data-aos="zoom-in" loading='lazy'
                        src={require("../../images/gallery/Avahanti_Homam/20240809_085617.webp")}
                        alt="A variety of healthy dishes on a table"
                        className="gallery-images"
                        style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                    />
                    <img data-aos="zoom-in" loading='lazy'
                        src={require("../../images/gallery/Surya_Namaskar/20240721_072839.webp")}
                        alt="Glasses of orange drink with strawberries and lime"
                        className="gallery-images"
                        style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                    />
                </a>

            </div>

            <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end", marginTop: "0px" }}>
                <a href="/gallery" style={{ textDecoration: "none" }}>
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        endIcon={<ArrowForward />}
                        sx={{ borderRadius: "10px", padding: "8px 16px", textTransform: "none" }}
                    >
                        View All
                    </Button>
                </a>
            </Box>

        </Box >
    );
};

export default GallerySection;
