import React, { useEffect } from "react";
import { Grid, Box, Typography, Button, Card, Chip, CardMedia, CardContent, Container } from "@mui/material";
import { useNavigate } from 'react-router-dom';

import { ArrowCircleRightSharp, Book, BookOnline, CardGiftcard, GifTwoTone } from '@mui/icons-material';
import { Gift, Event, Star, ShoppingCart } from '@mui/icons-material';


const Services = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);

    const navigate = useNavigate();

    const handleRouting = (homam) => {
        window.location.href = `/virtual-seva/${homam}`;
    };

    const products = [
        { id: "Avahanti-Homam", name: "Avahanti Homam", price: "₹500", img: require("../../images/landingservices/landing_avahanti.jpg"), label: "Popular", icon: <BookOnline />, buttonText: "Learn More", },
        { id: "Ganapathi-Homam", name: "Ganapathi Homam", price: "₹500", img: require("../../images/landingservices/landing_ganapathi.jpg"), label: "Popular", icon: <BookOnline />, buttonText: "Learn More", },
        { id: "Surya-Namaskaram", name: "Surya Namaskaram", price: "₹250", img: require("../../images/landingservices/landing_suryanamaskar.jpg"), label: "Popular", icon: <BookOnline />, buttonText: "Learn More", },
        { id: "pricing", name: "Premium", price: "₹499", img: require("../../images/landingservices/landing_premium.png"), label: "Featured", icon: <Star />, buttonText: "Learn More", },
        { id: "https://shop.shubadinam.com", name: "Gift Shoppe", price: "₹99 - ₹999 ", img: require("../../images/thumbnails/gift poster-01.png"), label: "Exclusive", icon: <CardGiftcard />, isExternal: true, buttonText: "Gift Now", },
        { id: "https://shop.shubadinam.com/collections/books", name: "Books", price: "₹99 - ₹999 ", img: require("../../images/landingservices/landing_books.jpg"), label: "Exclusive", icon: <Book />, buttonText: "Buy Now", isExternal: true, },
    ];

    return (
        <Box
            sx={{
                // background: 'linear-gradient(to bottom, #fdfbfb, #ebedee)',
                py: 4,
                minHeight: '100vh',
            }}
        >
            <Container maxWidth="lg">

                <Grid container spacing={4}>
                    {products.map((product, index) => (
                        <Grid item xs={6} sm={6} md={4} key={index}>
                            <Card
                                onClick={() => product.isExternal ? window.open(product.id, "_blank") : handleRouting(product.id)}
                                sx={{
                                    cursor: "pointer",
                                    borderRadius: 4,
                                    boxShadow: 2,
                                    overflow: 'hidden',
                                    position: 'relative',
                                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                    background: 'linear-gradient(to bottom, #ffffff, #f7f8fa)',
                                    '&:hover': {
                                        transform: 'translateY(-5px)',
                                        boxShadow: 10,
                                        background: 'linear-gradient(to bottom, #f7f8fa, #ffffff)',
                                    },
                                }}

                                data-aos="fade-up"
                                data-aos-delay={index * 100}
                            // data-aos-offset="-50"
                            >
                                {product.label && (
                                    <Chip
                                        size='small'
                                        label={product.label}
                                        color="primary"
                                        sx={{
                                            fontSize: "12px !important",
                                            position: 'absolute',
                                            p: 0,
                                            top: 4,
                                            right: 8,
                                            zIndex: 1,
                                            bgcolor: product.label === "Upcoming" ? "orange" : '#007bff',
                                        }}
                                    />
                                )}
                                <CardMedia
                                    component="img"
                                    height="auto"
                                    image={product.img}
                                    alt={product.name}
                                    sx={{
                                        objectFit: 'contain',
                                    }}
                                />
                                <CardContent
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'start',
                                        justifyContent: "start",
                                        background: '#f9f9f9',
                                        pb: "10px !important",
                                    }}
                                >
                                    <Typography
                                        variant="body1" // Adjust variant as needed; `body5` doesn't exist
                                        fontWeight="bold"
                                        sx={{
                                            color: '#34495e',
                                            textAlign: 'start',
                                            fontSize: {
                                                xs: '12px', // Font size for small screens (e.g., mobile)
                                                sm: '16px', // Font size for medium screens (e.g., tablets)
                                                md: '18px', // Font size for large screens (e.g., desktops)
                                            },
                                        }}
                                    >
                                        {product.name}
                                    </Typography>

                                    <Typography
                                        variant="subtitle2"
                                        color="text.secondary"
                                        sx={{
                                            fontSize: {
                                                xs: '12px', // Font size for small screens (e.g., mobile)
                                                sm: '16px', // Font size for medium screens (e.g., tablets)
                                                md: '18px', // Font size for large screens (e.g., desktops)
                                            },
                                            mb: 1,
                                            textAlign: "start"
                                        }}
                                    >
                                        Price:  {product.price}/-
                                    </Typography>
                                    <Button
                                        size='small'
                                        variant="contained"
                                        // startIcon={product.icon}
                                        sx={{
                                            bgcolor: "#263b77",
                                            fontSize: { xs: "14px !important", md: "1.5rem" },
                                            textTransform: 'none',
                                            borderRadius: 2,
                                            color: '#f0f0f0', // Light grey for contrast
                                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)', // Subtle shadow for depth
                                        }}
                                        disabled={product.buttonText === "Upcoming" ? true : false}
                                    >
                                        {product.buttonText}
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>


            </Container>
        </Box>

    );
};

export default Services;
