import React, { useEffect, useState } from 'react';
import { Container, Typography, Card, CardMedia, CardContent, Box, useMediaQuery, Button } from '@mui/material';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css'; // Import the default CSS for Splide
import { Skeleton } from '@mui/material'; // Import Skeleton component
import { NavLink } from 'react-router-dom';
import CardGiftcard from '@mui/icons-material/CardGiftcard';
import { useSwipeable } from 'react-swipeable';


const categories = [
    {
        name: 'Inspirational',
        img: require("../../images/books/inspiration_1.webp"),
        link: "https://shop.shubadinam.com/collections/inspirational",
        description: "Fuel your soul with stories that inspire and uplift."
    },
    {
        name: 'Spiritual',
        img: require("../../images/books/spiritual_1.webp"),
        link: "https://shop.shubadinam.com/collections/spiritual",
        description: "Discover divine wisdom and inner peace."
    },
    {
        name: 'Self-Help',
        img: require("../../images/books/self books.webp"),
        link: "https://shop.shubadinam.com/collections/self-help",
        description: "Unlock your potential and transform your life."
    },
    {
        name: 'Heritage',
        img: require("../../images/books/heritage_1.webp"),
        link: "https://shop.shubadinam.com/collections/heritage",
        description: "Explore timeless traditions and cultural roots."
    },
    {
        name: 'Motivational',
        img: require("../../images/books/motivation_1.webp"),
        link: "https://shop.shubadinam.com/collections/motivational",
        description: "Stay driven with books that ignite success."
    },
];

const BooksCategory = () => {


    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => console.log('Swiped Left'),
        onSwipedRight: () => console.log('Swiped Right'),
        trackMouse: true,  // Optional: track mouse events for swipe-like behavior on desktop
    });


    return (
        <Box sx={{
            display: 'flex',
            overflowX: 'auto',  // Enables horizontal scrolling
            position: 'relative',
            width: '100%',
            background: 'slategrey',
            padding: 2,
            borderRadius: 1,
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.05)',
            '&::-webkit-scrollbar': {
                height: '8px', // Custom height of the scrollbar
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#053559',  // Custom color for the thumb (the part that moves)
                borderRadius: '4px',
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#fff',  // Custom color for the track (the part the thumb moves along)
            },
        }}>
            <Box
                sx={{
                    display: 'flex',
                    gap: 2,
                    width: 'fit-content',
                }}
                {...swipeHandlers} // Attach swipeable handlers here
            >
                {[...categories].map((category, index) => (
                    <Card
                        onClick={() => { window.open(category.link, '_blank'); }}  // Opens the link in a new tab
                        key={index}
                        sx={{
                            cursor: 'pointer',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            borderRadius: 2,
                            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                            '&:hover': {
                                transform: 'scale(1.05)',
                                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                            },
                            marginRight: 2,
                            width: 200,
                        }}
                        data-aos="fade-up"
                        data-aos-delay={200 * index}
                    >
                        <CardMedia
                            component="img"
                            height="150"
                            image={category.img}
                            alt={category.title}
                            sx={{
                                borderTopLeftRadius: 2,
                                borderTopRightRadius: 2,
                                bgcolor: "#eeee",
                                objectFit: "contain"
                            }}
                        />
                        <CardContent sx={{ paddingBottom: 2 }}>
                            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', color: '#333', fontSize: '1em' }}>
                                {category.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.9rem' }}>
                                {category.description}
                            </Typography>
                        </CardContent>
                    </Card>
                ))}
            </Box>
        </Box>
    )
}

export default BooksCategory