import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container, Box, Typography, Button, Tooltip, TablePagination, TextField, InputAdornment, IconButton } from "@mui/material";
import SDadminHeader from "./SDadminHeader";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Approval, ArrowRightAlt, CheckCircle, NotAccessible, Visibility as VisibilityIcon } from "@mui/icons-material";
import Rating from '@mui/material/Rating';
import Search from "@mui/icons-material/Search";
import Cancel from "@mui/icons-material/Cancel";

const AdminFeedback = () => {

    const [feedbacks, setFeedbacks] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    const handleViewClick = (row) => {
        // Encode the feedback data into a query parameter
        const queryParams = new URLSearchParams(row).toString();
        const feedbackDetailUrl = `/admin/feedback-detail?${queryParams}`;

        // Open the new tab with the feedback details page
        window.open(feedbackDetailUrl, "_blank");
    };

    useEffect(() => {
        axios.get("https://backend.shubadinam.com/fetch/feedback")
            .then(response => setFeedbacks(response.data))
            .catch(error => console.error("Error fetching feedback:", error));
    }, []);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5); // Default 5 rows per page

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Handle search filtering
    const filteredFeedbacks = feedbacks.filter(row =>
        row.userName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.explanation.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Box sx={{ bgcolor: "aliceblue" }}>
            <SDadminHeader />
            <Container sx={{ mt: 10, py: 10, bgcolor: "aliceblue" }}>
                <Typography variant="h6" gutterBottom>
                    Customer Feedbacks and Reviews
                </Typography>

                {/* Search Field */}
                <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "end", alignItems: "end" }}>

                    <TextField
                        label="Search Feedback"
                        variant="outlined"
                        sx={{ mb: 2, maxWidth: 400, borderRadius: 2, }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />

                </Box>

                <TableContainer component={Paper} sx={{ maxWidth: "100%", overflowX: "auto", borderRadius: 2, boxShadow: 3 }}>
                    <Table sx={{ minWidth: 650, borderCollapse: "collapse" }}>
                        <TableHead>
                            <TableRow sx={{ backgroundColor: "#f4f4f4" }}>
                                <TableCell sx={{ fontWeight: 'bold', color: "#4A4A4A", padding: '12px 16px', fontSize: "14px" }}>S.No</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', color: "#4A4A4A", padding: '12px 16px', fontSize: "14px" }}>Name</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', color: "#4A4A4A", padding: '12px 16px', fontSize: "14px" }}>Category</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', color: "#4A4A4A", padding: '12px 16px', fontSize: "14px" }}>Review/Explanation</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', color: "#4A4A4A", padding: '12px 16px', fontSize: "14px" }}>Rating</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', color: "#4A4A4A", padding: '12px 16px', fontSize: "14px" }}>Reviewed On</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', color: "#4A4A4A", padding: '12px 16px', fontSize: "14px" }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredFeedbacks.slice().reverse().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                <TableRow
                                    onClick={() => handleViewClick(row)}
                                    key={index}
                                    sx={{
                                        cursor: "pointer",
                                        "&:hover": { backgroundColor: "#e1f5fe", transition: "background-color 0.3s ease" },
                                        backgroundColor: "#ffffff",
                                    }}
                                >
                                    <TableCell sx={{ padding: '12px 16px', fontSize: "14px", color: "#333" }}>{row.id}</TableCell>
                                    <TableCell sx={{ padding: '12px 16px', fontSize: "14px", color: "#333" }}>{row.userName}</TableCell>
                                    <TableCell sx={{ padding: '12px 16px', fontSize: "14px", color: "#333" }}>{row.category}</TableCell>
                                    <Tooltip title={row.explanation} arrow>
                                        <TableCell
                                            sx={{
                                                padding: '12px 16px',
                                                fontSize: "14px",
                                                color: "#333",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            {row.explanation}
                                        </TableCell>
                                    </Tooltip>

                                    <TableCell sx={{ padding: '12px 16px', fontSize: "14px", color: "#333" }}>
                                        <Rating value={row.rating} readOnly precision={0.1} />
                                    </TableCell>
                                    <TableCell sx={{ padding: '12px 16px', fontSize: "14px", color: "#333" }}>
                                        {new Date(row.created_at).toLocaleString('en-US', {
                                            weekday: 'short',
                                            year: 'numeric',
                                            month: 'short',
                                            day: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                        })}
                                    </TableCell>
                                    <TableCell sx={{ display: "flex", justifyContent: "space-evenly", flexDirection: "row" }}>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            endIcon={<ArrowRightAlt />}
                                            onClick={() => handleViewClick(row)}
                                            sx={{
                                                textTransform: "none",
                                                backgroundColor: "#0A78A9",
                                                color: "#fff",
                                                "&:hover": { backgroundColor: "#0277BD" },
                                            }}
                                        >
                                            View
                                        </Button>
                                        <IconButton>
                                            {row.approved === 1 ? (
                                                <CheckCircle sx={{ color: "green" }} />
                                            ) : (
                                                <Cancel sx={{ color: "red" }} />
                                            )}
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Pagination */}
                <TablePagination
                    sx={{ mt: 5, px: 5, bgcolor: "white" }}
                    rowsPerPageOptions={[5, 10, 25]} // Options for rows per page
                    component="div"
                    count={feedbacks.length} // Total number of feedbacks
                    rowsPerPage={rowsPerPage} // Number of rows per page
                    page={page} // Current page
                    onPageChange={handleChangePage} // Handle page change
                    onRowsPerPageChange={handleChangeRowsPerPage} // Handle rows per page change
                />
            </Container>
        </Box>
    );
};

export default AdminFeedback;