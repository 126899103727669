import React, { useEffect, useState } from "react";
import { Typography, Container, Box, Paper, Divider, Grid, Rating, Card, CardContent, Button, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useTheme } from '@mui/system';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import SDadminHeader from "./SDadminHeader";
import { Accordion, AccordionSummary, AccordionDetails, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Edit from "@mui/icons-material/Edit";
import CheckCircle from "@mui/icons-material/CheckCircle";
import axios from "axios";


const FeedbackDetail = () => {

    const theme = useTheme();

    const [feedback, setFeedback] = useState(null);

    const [openDialog, setOpenDialog] = useState(false);
    const [explanation, setExplanation] = useState(feedback?.explanation);

    const [formData, setFormData] = useState({});
    const [changedFields, setChangedFields] = useState({}); // To track changes

    useEffect(() => {
        // Parse the query parameters from the URL
        const queryParams = new URLSearchParams(window.location.search);
        const feedbackData = {};

        queryParams.forEach((value, key) => {
            feedbackData[key] = value;
        });

        setFeedback(feedbackData);
        setFormData(feedbackData); // Store original values in formData
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;

        setFormData((prev) => ({
            ...prev,
            [name]: value
        }));

        // Check if value is different from original feedback and mark as changed
        setChangedFields((prev) => ({
            ...prev,
            [name]: feedback[name] !== value
        }));
    };

    const handleRatingChange = (event, newValue) => {
        setFormData((prev) => ({
            ...prev,
            rating: newValue
        }));

        setChangedFields((prev) => ({
            ...prev,
            rating: feedback.rating !== newValue
        }));
    };


    const handleDialogOpen = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleApprove = async (feedback) => {
        try {
            const response = await axios.put(`https://backend.shubadinam.com/approve-feedback/${feedback.id}`, {
                approved: true,
            });

            alert(response.data.message);
        } catch (error) {
            console.error("Error updating approval status:", error);
        }
    };


    const handleSaveChanges = async (feedback) => {
        const formDataToSend = new FormData();
        Object.keys(formData).forEach((key) => {
            formDataToSend.append(key, formData[key]);
        });

        try {
            const response = await axios.put(`https://backend.shubadinam.com/update-feedback/${feedback.id}`, formDataToSend, {
                headers: { "Content-Type": "multipart/form-data" },
            });

            alert(response.data.message);
            console.log(formData);
            setOpenDialog(false);
        } catch (error) {
            console.error("Error updating feedback:", error);
        }
    };

    const handleDiscardChanges = () => {
        // Discard the changes and close the dialog
        setExplanation(feedback.explanation);
        setOpenDialog(false);
    };

    return (
        <Box sx={{ bgcolor: "aliceblue" }}>
            <SDadminHeader />
            <Container sx={{ py: { xs: 10, xl: 20 }, maxWidth: 'md', bgcolor: "aliceblue" }}>
                <Paper sx={{ p: 4, borderRadius: 3, boxShadow: 0, bgcolor: "aliceblue" }}>



                    {feedback ? (
                        <>
                            {/* Bio Data Section in Grid Cards */}

                            <Typography variant="h5" sx={{ textAlign: 'left', mb: 4, color: "#1976d2" }}>
                                Detailed Review / Feedback from <Typography variant="span" sx={{ fontWeight: 600, textAlign: 'center', mb: 4, color: theme.palette.primary.main }}>{feedback.userName}</Typography>,
                            </Typography>
                            <Grid container spacing={3} justifyContent="center">
                                <Grid item xs={12} >
                                    <Accordion defaultExpanded={true}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
                                                User Information
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Table>
                                                <TableBody>
                                                    {/* Keys Row */}
                                                    <TableRow>
                                                        <TableCell><strong>Name</strong></TableCell>
                                                        <TableCell><strong>Email</strong></TableCell>
                                                        <TableCell><strong>Phone</strong></TableCell>
                                                        <TableCell><strong>Country</strong></TableCell>
                                                        <TableCell><strong>State</strong></TableCell>
                                                        <TableCell><strong>UniqueId</strong></TableCell>
                                                    </TableRow>

                                                    {/* Values Row */}
                                                    <TableRow>
                                                        <TableCell>{feedback.userName}</TableCell>
                                                        <TableCell>{feedback.userEmail}</TableCell>
                                                        <TableCell>{feedback.userPhone}</TableCell>
                                                        <TableCell>{feedback.userCountry}</TableCell>
                                                        <TableCell>{feedback.userState}</TableCell>
                                                        <TableCell>{feedback.uniqueId}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </AccordionDetails>

                                    </Accordion>
                                </Grid>
                            </Grid>


                            {/* Feedback Section */}
                            <TableContainer component={Paper} sx={{ mt: 5 }} >
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={2} sx={{ fontWeight: 'bold', backgroundColor: '#f4f6f8', fontSize: "larger" }}>
                                                Feedback Details
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center"><strong>Category:</strong></TableCell>
                                            <TableCell>{feedback.category}</TableCell>
                                        </TableRow>
                                        {
                                            feedback.bookTitle &&
                                            <TableRow>
                                                <TableCell align="center"><strong>Book Title</strong></TableCell>
                                                <TableCell>{feedback.bookTitle}</TableCell>
                                            </TableRow>
                                        }

                                        <TableRow>
                                            <TableCell align="center"><strong>Review:</strong></TableCell>
                                            <TableCell>{feedback.explanation}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center"><strong>Rating:</strong></TableCell>
                                            <TableCell>
                                                <Rating value={feedback.rating} readOnly />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center"><strong>Reviewed On:</strong></TableCell>
                                            <TableCell > {new Date(feedback.created_at).toLocaleString('en-US', {
                                                weekday: 'short',
                                                year: 'numeric',
                                                month: 'short',
                                                day: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit',
                                            })}</TableCell> {/* Assuming you have this field */}
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center"><strong>Actions</strong></TableCell>
                                            <TableCell sx={{ display: 'flex', justifyContent: 'start', padding: 2 }}>
                                                <Button
                                                    onClick={() => handleDialogOpen()}
                                                    variant="contained"
                                                    color="primary"
                                                    endIcon={<Edit />}
                                                    sx={{ marginRight: 1, borderRadius: 2, paddingX: 3 }}
                                                >
                                                    Rewrite
                                                </Button>
                                                <Button
                                                    onClick={() => handleApprove(feedback)}
                                                    variant="contained"
                                                    color="success"
                                                    endIcon={<CheckCircle />}
                                                    sx={{ borderRadius: 2, paddingX: 3 }}
                                                >
                                                    Approve
                                                </Button>
                                            </TableCell>

                                        </TableRow>

                                    </TableBody>

                                </Table>
                            </TableContainer>

                            {/* Dialog for "Rewrite" */}
                            <Dialog
                                open={openDialog}
                                onClose={handleDialogClose}
                                fullWidth
                                maxWidth={false}
                                sx={{ width: "100%", }}  // Ensures the dialog stretches to full width
                            >
                                <DialogTitle > Edit Feedback / Review</DialogTitle>
                                <DialogContent sx={{ padding: 2 }}>
                                    <Grid container spacing={2} sx={{ mt: 2 }}>
                                        {/* Name Field */}
                                        {/* <Grid item xs={12} sm={4}>
                                            <TextField
                                                size="small"
                                                label="Name"
                                                fullWidth
                                                variant="outlined"
                                                name="userName"
                                                value={formData.userName || ""}
                                                onChange={handleChange}
                                                error={changedFields.userName} // Highlight if changed
                                                helperText={changedFields.userName ? "Modified" : ""}
                                            />
                                        </Grid> */}



                                        {/* Category Dropdown */}
                                        {/* <Grid item xs={12} sm={4}>
                                            <FormControl fullWidth>
                                                <InputLabel>Category</InputLabel>
                                                <Select
                                                    size="small"
                                                    label="Category"
                                                    name="category"
                                                    value={formData.category || ""}
                                                    onChange={handleChange}
                                                    error={changedFields.category}
                                                >
                                                    <MenuItem disabled value="">Select Category</MenuItem>
                                                    <MenuItem value="book review">Book Review</MenuItem>
                                                    <MenuItem value="blogs">Book Review</MenuItem>
                                                    <MenuItem value="website feedback">Website Feedback</MenuItem>
                                                    <MenuItem value="others">Others</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid> */}

                                        {/* Explanation TextArea */}
                                        <Grid item xs={8} >
                                            <TextField
                                                fullWidth
                                                label="Type something here"
                                                name="explanation"
                                                value={formData.explanation}
                                                onChange={handleChange} variant="outlined"
                                                margin="normal"
                                                error={changedFields.explanation} // Highlight if changed
                                                helperText={changedFields.explanation ? "Modified" : ""}
                                                multiline
                                                rows={6}
                                            />
                                        </Grid>

                                        {/* {formData.category === "others" &&

                                            <Grid item xs={12} sm={8}>
                                                <TextField
                                                    fullWidth
                                                    label="Specify"
                                                    name="explanation"
                                                    value={formData.explanation}
                                                    onChange={handleChange} variant="outlined"
                                                    margin="normal"
                                                    error={changedFields.explanation} // Highlight if changed
                                                    helperText={changedFields.explanation ? "Modified" : ""} />
                                            </Grid>
                                        }
                                        {formData.category === "website feedback" && (
                                            <Grid item xs={12} sm={8}>
                                                <TextField fullWidth
                                                    label="Type Something" multiline
                                                    rows={4}
                                                    name="explanation"
                                                    onChange={handleChange}
                                                    error={changedFields.explanation} // Highlight if changed
                                                    value={formData.explanation} variant="outlined"
                                                    margin="normal"
                                                    helperText={changedFields.explanation ? "Modified" : ""} />
                                            </Grid>
                                        )}
                                        {formData.category === "book review" && (
                                            <Grid item xs={12} sm={8}>
                                                <TextField fullWidth
                                                    label="Book Title"
                                                    value={formData.bookTitle}
                                                    name="bookTitle"
                                                    error={changedFields.bookTitle} // Highlight if changed
                                                    onChange={handleChange}
                                                    variant="outlined"
                                                    margin="normal"
                                                    helperText={changedFields.bookTitle ? "Modified" : ""}
                                                />

                                                <TextField
                                                    fullWidth
                                                    label="Type Something"
                                                    multiline
                                                    value={formData.explanation}
                                                    rows={6}
                                                    name="explanation"
                                                    error={changedFields.explanation} // Highlight if changed
                                                    onChange={handleChange} variant="outlined"
                                                    margin="normal" helperText={changedFields.explanation ? "Modified" : ""}
                                                />

                                            </Grid>
                                        )} */}


                                    </Grid>

                                    {/* Rating Section */}
                                    <Grid item xs={12} sm={8} container alignItems="center" spacing={2} sx={{ mt: 0.5 }}>
                                        <Grid item>
                                            <Typography variant="body2" color="gray">
                                                Rating:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Rating
                                                value={formData.rating || 0}
                                                onChange={handleRatingChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2" color={changedFields.rating ? "error" : "textSecondary"}>
                                                {formData.rating === 1
                                                    ? "Poor"
                                                    : formData.rating === 2
                                                        ? "Fair"
                                                        : formData.rating === 3
                                                            ? "Good"
                                                            : formData.rating === 4
                                                                ? "Very Good"
                                                                : formData.rating === 5
                                                                    ? "Excellent"
                                                                    : ""}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </DialogContent>


                                <DialogActions sx={{ padding: 2, justifyContent: 'end' }}>
                                    <Button
                                        onClick={handleDiscardChanges}
                                        color="secondary"
                                        variant="outlined"
                                        sx={{
                                            borderRadius: 2,
                                            paddingX: 3,
                                            paddingY: 1.5,
                                            backgroundColor: "grey", // Custom color
                                            color: "white",
                                            "&:hover": {
                                                backgroundColor: "lightslategrey", // Hover effect
                                                color: "white",
                                            },
                                        }}
                                    >
                                        Discard
                                    </Button>
                                    <Button
                                        onClick={() => handleSaveChanges(feedback)}
                                        color="primary"
                                        variant="contained"
                                        sx={{
                                            borderRadius: 2,
                                            paddingX: 3,
                                            paddingY: 1.5,
                                            backgroundColor: "#1976d2", // Custom color
                                            "&:hover": {
                                                backgroundColor: "#1565c0", // Hover effect
                                                color: "white",
                                            },
                                            transition: "all 0.3s ease-in-out",
                                        }}
                                    >
                                        Save Changes
                                    </Button>
                                </DialogActions>

                            </Dialog>
                        </>
                    ) : (
                        <TableRow>
                            <TableCell colSpan={2} align="center">
                                <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
                                    Loading feedback details...
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </Paper>
            </Container>


        </Box>
    );
};

export default FeedbackDetail;
