import React from "react";
import { NavLink } from "react-router-dom";
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import YouTubeIcon from '@mui/icons-material/YouTube';

const FooterComponent = () => {



    const facebookURL = 'https://www.facebook.com/profile.php?id=61551460127861';
    const instagramURL = 'https://www.instagram.com/shubadinam/';
    const youtubeURL = 'https://www.youtube.com/channel/UCsNbE7QqnpgjI7cXOTM1mXA';
    const twitterURL = 'https://twitter.com/Shubadinam';
    const whatsappURL = 'https://www.youtube.com/channel/UCsNbE7QqnpgjI7cXOTM1mXA';





    const handleIconClick = (iconCount) => {
        // console.log('Icon clicked:', iconCount);
        if (iconCount === 2) {
            window.open(facebookURL, '_blank');
        }
        else if (iconCount === 3) {
            window.open(instagramURL, '_blank');
        }
        else if (iconCount === 4) {
            window.open(youtubeURL, '_blank');
        }
        else if (iconCount === 1) {
            window.open(twitterURL, '_blank');
        }
        else {
            // window.open(whatsappURL, '_blank');
        }

    };








    return (
        <>
            {/* ======= Footer ======= */}
            <footer id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li><i className="bx bx-chevron-right" /> <a href="/">Home</a></li>
                                    <li><i className="bx bx-chevron-right" /> <a href="/about-us">About us</a></li>
                                    <li><i className="bx bx-chevron-right" /> <a href="/faq">FAQs</a></li>
                                    <li><i className="bx bx-chevron-right" /> <a href="/termsandconditions">Terms of service</a></li>
                                    <li><i className="bx bx-chevron-right" /> <a href="/privacypolicy">Privacy policy</a></li>
                                    <li><i className="bx bx-chevron-right" /> <a href="/why-us">Why Us</a></li>
                                    <li><i className="bx bx-chevron-right" /> <a href="/contact-us">Contact Us</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4>Our Services</h4>
                                <ul>
                                    <li><i className="bx bx-chevron-right" /> <a href="/blogs">Blogs</a></li>
                                    <li>
                                        <i className="bx bx-chevron-right" />
                                        <a href="https://shop.shubadinam.com/" target="_blank" rel="noopener noreferrer">Gift Shoppe</a>
                                    </li>

                                    <li><i className="bx bx-chevron-right" /> <a href="/dashboard">Find Janma Nakshatra /  Tithi</a></li>
                                    {/* <li><i className="bx bx-chevron-right" /> <a href="#">Marketing</a></li> */}
                                    {/* <li><i className="bx bx-chevron-right" /> <a href="#">Graphic Design</a></li> */}
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-6 footer-contact">
                                <h4>Contact Us</h4>
                                <p>
                                    {/* Adambakkam,<br />
                                        Chennai,{" "}600 088<br />
                                        TamilNadu.<br /><br /> */}
                                    <strong>Phone:</strong> +91 8925251938<br />
                                    <strong>Email:</strong> support@shubadinam.com<br />
                                </p>
                            </div>
                            <div className="col-lg-3 col-md-6 footer-info">
                                <h3>About Shubadinam</h3>
                                <p>Shubadinam stands as a humble offering toward the noble cause of Retaining and Reclaiming the profound cultural legacy of Hinduism.</p>
                                <div className="social-links mt-3">
                                    <a className="twitter" onClick={() => handleIconClick(1)}>
                                        <TwitterIcon fontSize="small" />
                                    </a>
                                    <a className="facebook" onClick={() => handleIconClick(2)}>
                                        <FacebookIcon fontSize="small" />
                                    </a>
                                    <a className="instagram" onClick={() => handleIconClick(3)}>
                                        <InstagramIcon fontSize="small" />
                                    </a>
                                    <a className="whatsapp" onClick={() => handleIconClick()}>
                                        <WhatsAppIcon fontSize="small" />
                                    </a>
                                    <a className="youtube" onClick={() => handleIconClick(4)}>
                                        <YouTubeIcon fontSize="small" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="copyright">
                        © Copyright <strong><span>Shubadinam</span></strong>. All Rights Reserved
                    </div>
                    <div className="credits">
                        <a href="/pricing-policy" style={{ marginRight: '10px' }}>Pricing policy</a>
                        |
                        <a href="/shipping" style={{ margin: '0 10px' }}>Shipping</a>
                        |
                        <a href="/refundandcancellationpolicy" style={{ marginLeft: '10px' }}>Refund and Cancellation</a>


                    </div>
                </div>
            </footer>{/* End Footer */}

        </>

    )
}
export default FooterComponent;