import React, { useState } from "react";
import { Box, Container, Grid, Typography, Button, IconButton, useTheme, useMediaQuery } from "@mui/material";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SlidingHeader from "../../components/SlidingHeader";

import blueBanner from "../../images/banners/blue-banner-01.png"
import greenBanner from "../../images/banners/green-banner-01.png"
import brownBanner from "../../images/banners/brown-banner-01.png"

import bluePh from "../../images/banners/blue-ph.png"
import greenPh from "../../images/banners/green-ph.png"
import brownPh from "../../images/banners/brown-ph.png"


import { Login, PlayArrow } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Dashboard from "@mui/icons-material/Dashboard";

const HeroSlider = () => {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("lg"));

    const navigate = useNavigate();

    const [isPlaying, setIsPlaying] = useState(false);
    const [bgColor, setBgColor] = useState("aliceblue"); // Start with a slightly darker color
    const [textColor, setTextColor] = useState("#003a70");

    const handlePlayClick = () => setIsPlaying((prev) => !prev);

    const handleSlideChange = (splide) => {
        // const colors = ["#d0efff", "#c8ffe8", "#ffe9c7"]; // Slightly darker tones
        const colors = ["aliceblue", "#effbf8", "#fffaf1"];

        const textColors = ["#003a70", "#1d5e3a", "#5f3e02"]; // Complementary darker text colors
        const newBgColor = colors[splide.index % colors.length];
        const newTextColor = textColors[splide.index % colors.length];

        setBgColor(newBgColor);
        setTextColor(newTextColor);
    };

    const isLogged = localStorage.getItem("token");


    return (
        <Box
            sx={{
                p: 2,
                position: "relative",
                overflow: "hidden",
                backgroundColor: bgColor,
                color: textColor,
                transition: "background-color 1s ease, color 1s ease", // Smooth transition
            }}
        >
            <SlidingHeader />
            {/* Content */}
            <Box
                sx={{
                    mt: { xs: 8, xl: 15 },
                    position: "relative",
                    zIndex: 2,
                }}
            >
                {/* Slider Section */}
                <Box sx={{ width: "100%", maxWidth: "1500px", margin: "auto", }}>
                    <Splide
                        onMove={handleSlideChange}
                        options={{
                            type: "loop",
                            autoplay: true,
                            interval: 3000,
                            arrows: false,
                            pagination: true,
                        }}
                    >
                        {/* Slide 1 */}
                        <SplideSlide>
                            <a href="/pricing">
                                <Box
                                    sx={{
                                        height: { xs: "150px", md: "200px" },
                                        backgroundImage: `url(${mobile ? bluePh : blueBanner})`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        borderRadius: "20px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        position: "relative",
                                        overflow: "hidden",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            width: "100%",
                                            height: "100%",
                                            // backgroundColor: "rgba(0, 0, 0, 0.3)", // Optional overlay
                                            borderRadius: "20px",
                                        }}
                                    ></Box>

                                </Box>
                            </a>
                        </SplideSlide>
                        {/* Slide 2 */}
                        <SplideSlide>
                            <a href="https://shop.shubadinam.com/" target="_blank">
                                <Box
                                    sx={{
                                        height: { xs: "150px", md: "200px" },
                                        backgroundImage: `url(${mobile ? greenPh : greenBanner})`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        borderRadius: "20px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        position: "relative",
                                        overflow: "hidden",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            width: "100%",
                                            height: "100%",
                                            // backgroundColor: "rgba(0, 0, 0, 0.3)", // Optional overlay
                                            borderRadius: "20px",
                                        }}
                                    ></Box>

                                </Box>
                            </a>
                        </SplideSlide>
                        {/* Slide 3 */}
                        <SplideSlide>
                            <a href="/virtual-puja-seva">
                                <Box
                                    sx={{
                                        height: { xs: "150px", md: "200px" },
                                        backgroundImage: `url(${mobile ? brownPh : brownBanner})`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        borderRadius: "20px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        position: "relative",
                                        overflow: "hidden",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            width: "100%",
                                            height: "100%",
                                            // backgroundColor: "rgba(0, 0, 0, 0.3)", // Optional overlay
                                            borderRadius: "20px",
                                        }}
                                    ></Box>

                                </Box>
                            </a>
                        </SplideSlide>
                    </Splide>

                </Box>

                {/* Hero Content Section */}
                <Container maxWidth="xl" sx={{ py: { xs: 3, md: 5 } }}>
                    <Grid container spacing={4} alignItems="center">
                        {/* Left Section */}
                        <Grid item xs={12} md={6}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h4" sx={{ fontWeight: "bold", color: textColor, mb: 2 }}>
                                    Birthdays are Special
                                </Typography>
                                <Typography variant="body1" sx={{ color: textColor, mb: 2, fontSize: "large" }}>
                                    Make it Impactful... Make it Shubadinam...
                                </Typography>
                                <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    sx={{
                                        mb: { xs: 2, sm: 3, md: 4 },
                                        fontSize: { xs: "0.975rem", sm: "1rem", md: "1.125rem", xl: "1.5rem" },
                                        textAlign: { xs: "left", md: "left" },
                                    }}
                                >
                                    Get reminders to celebrate the Janma Nakshatra / Janma Tithi as per the Indian calendar and make every
                                    celebration memorable.
                                </Typography>
                                <Box sx={{ display: "flex", gap: 2 }}>
                                    <Button
                                        startIcon={isLogged ? <Dashboard /> : <Login />}
                                        onClick={() => navigate(isLogged ? "/dashboard" : "/register")}
                                        variant="contained"
                                        size="small"
                                        sx={{
                                            fontSize: "1rem",
                                            border: "1px solid transparent",
                                            textTransform: "none",
                                            color: "white",
                                            bgcolor: textColor,
                                            borderRadius: 1,
                                            "&:hover": {
                                                border: "1px solid black",
                                                bgcolor: "white",
                                                color: "#1b3e69",
                                                boxShadow: 2,
                                            },
                                        }}
                                    >
                                        {isLogged ? "Dashboard" : "Sign Up Now"}
                                    </Button>
                                    <Button
                                        startIcon={<PlayArrowIcon />}
                                        onClick={handlePlayClick}
                                        variant="outlined"
                                        size="small"
                                        sx={{
                                            color: "#1b3e69",
                                            bgcolor: "#fff",
                                            fontSize: "1rem",
                                            textTransform: "none",
                                        }}
                                    >
                                        Play Video
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                        {/* Right Section */}
                        <Grid item xs={12} md={6} sx={{ position: 'relative', pt: { xs: "10px !important", md: "20px !important" } }}>
                            <Box onClick={handlePlayClick}
                                sx={{
                                    cursor: "pointer",
                                    width: { xs: '100%', sm: '100%' },
                                    padding: { xs: '10px', sm: '10px' },
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    position: 'relative',
                                    borderRadius: '8px', // Add rounded corners for a modern look
                                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Soft shadow for depth
                                    overflow: 'hidden', // Ensures that the image and iframe are clipped to rounded corners
                                    backgroundImage: !isPlaying
                                        ? `url(${require("../../images/thumbnails/lg_hero.png")})`
                                        : 'none', // Set the background image when not playing
                                    backgroundSize: 'cover', // Ensure the image covers the entire container
                                    backgroundPosition: 'center', // Keep the background centered
                                    backgroundRepeat: 'no-repeat', // Prevent image repetition
                                    height: '300px', // Set a fixed height for the container
                                    transition: 'background 0.3s ease', // Smooth transition for the background change
                                }}
                            >
                                {/* Play Button */}
                                {!isPlaying && (
                                    <IconButton
                                        size='large'
                                        sx={{
                                            position: 'absolute',
                                            zIndex: 3,
                                            fontSize: '60px', // Larger play button
                                            color: '#fff',
                                            backgroundColor: "black",
                                            opacity: 0.8,
                                            transition: 'opacity 0.3s ease',
                                            '&:hover': {
                                                opacity: 1, // On hover, make the play button fully visible
                                            },
                                        }}
                                        onClick={handlePlayClick}
                                    >
                                        <PlayArrow />
                                    </IconButton>
                                )}

                                {/* Video Iframe */}
                                {isPlaying && (
                                    <iframe
                                        width="100%"
                                        height="100%"
                                        src="https://www.youtube.com/embed/x1YUd2Ane2s?autoplay=1"
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        style={{ borderRadius: '8px' }} // Keep iframe with rounded corners
                                    ></iframe>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
};

export default HeroSlider;
